import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Block, Text } from 'components/atoms'
import { Header, PersonalInfo, CollectSection } from 'components/molecules'
import { MyCardSection } from 'components/organisms'
import withProfile from 'hocs/withProfile'
import get from 'lodash/get'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

const MyAccount = ({ profile, addresses }) => {
  const [category, setCategory] = React.useState('My Card')
  const [isShowMenu, setIsShowMenu] = React.useState(true)
  const point = get(profile, 'point', 0)
  const intl = useIntl()

  const renderTextButton = type => {
    return (
      <Block
        height={'100%'}
        flex={1}
        css={{
          cursor: 'pointer',
        }}
        textAlign={'center'}
        onClick={() => setCategory(type)}
      >
        <Text
          style={{
            borderBottom: type === category && `3.5px solid #EF5B34`,
          }}
          py={'10px'}
          variant={type === category ? 'h3' : 'subtitle1'}
        >
          {type}
        </Text>
      </Block>
    )
  }

  return (
    <Block>
      <Header boxShadow />
      <Block display={'flex'} height={'100%'}>
        <Block
          width={'23vw'}
          pl={'2vw'}
          display={['none', 'block']}
          minHeight={['auto', '87vh']}
          boxShadow={'4px 0px 5px -2px lightgrey'}
        >
          <List component="nav">
            <ListItem
              button
              onClick={() => setCategory('My Card')}
              component="div"
              divider
            >
              <Text
                variant={category === 'My Card' ? 'h3' : 'subtitle1'}
                my={'4px'}
              >
                {intl.formatMessage({ id: 'MyCard' })}
              </Text>
            </ListItem>
            <ListItem
              button
              onClick={() => setCategory('Collect')}
              component="div"
              divider
            >
              <Text
                variant={category === 'Collect' ? 'h3' : 'subtitle1'}
                my={'4px'}
              >
                {intl.formatMessage({ id: 'Collect' })}
              </Text>
            </ListItem>
            <ListItem
              button
              onClick={() => setCategory('Profile')}
              component="div"
              divider
            >
              <Text
                variant={category === 'Profile' ? 'h3' : 'subtitle1'}
                my={'4px'}
              >
                {intl.formatMessage({ id: 'Profile' })}
              </Text>
            </ListItem>
          </List>
        </Block>
        <Block
          maxWidth={1200}
          mx={'auto'}
          pt={[0, 25]}
          pb={[40, 0]}
          width={'100%'}
        >
          <Block
            className="menu"
            mt={[10, 0]}
            display={[isShowMenu ? 'flex' : 'none', 'none']}
            borderBottom={'2px solid #e6e6e6'}
          >
            {renderTextButton('My Card')}
            {renderTextButton('Collect')}
            {renderTextButton('Profile')}
          </Block>
          {category === 'My Card' && (
            <MyCardSection
              point={point}
              profile={profile}
              showMenu={setIsShowMenu}
              gotoCollectSection={() => setCategory('Collect')}
            />
          )}
          {category === 'Collect' && <CollectSection />}
          {category === 'Profile' && (
            <PersonalInfo profile={profile} addresses={addresses} />
          )}
        </Block>
      </Block>
    </Block>
  )
}

export default withProfile(MyAccount)
